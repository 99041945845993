<template>
  <div class="main main--internal">
    <div class="modal__login">
      <form class="modal__body" @submit.prevent="reset">
        <span class="modal__login__title">Введите новый пароль</span>
        <InputField
          :required="true"
          :classes="'mb-12'"
          :title="'E-mail'"
          :placeholder="'E-mail'"
          v-model="userForm.email.value"
        />
        <span class="modal__login__error" v-if="userForm.email.message">{{ userForm.email.message }}</span>
        <InputField
          :required="true"
          :type="'password'"
          :classes="'mb-12'"
          :title="'Пароль'"
          :placeholder="'Пароль'"
          v-model="userForm.password.value"
        />
        <span class="modal__login__error" v-if="userForm.password.message">{{ userForm.password.message }}</span>
        <InputField
          :required="true"
          :type="'password'"
          :classes="'mb-12'"
          :title="'Повторите пароль'"
          :placeholder="'Повторите пароль'"
          v-model="userForm.password_confirmation.value"
        />
        <span class="modal__login__error" v-if="userForm.password_confirmation.message">
          {{ userForm.password_confirmation.message }}
        </span>
        <span class="modal__login__error" v-if="userForm.token.message">{{ userForm.token.message }}</span>
        <div class="modal__login__row">
          <button class="modal__login__button" type="submit">
            <LoadingIndicator title="Ждите" v-if="loading"/>
            <template v-else>Сохранить</template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import LoadingIndicator from 'components/LoadingIndicator.vue'
import InputField from 'components/InputField.vue'

export default {
  name: 'ResetPasswordPage',
  components: {
    LoadingIndicator,
    InputField
  },
  data () {
    return {
      loading: false,
      userForm: {
        email: {
          value: '',
          message: ''
        },
        token: {
          message: ''
        },
        password: {
          value: '',
          message: ''
        },
        password_confirmation: {
          value: '',
          message: ''
        }
      }
    }
  },
  methods: {
    resetValidation () {
      Object.keys(this.userForm).forEach(field => {
        this.userForm[field].message = null
      })
    },
    reset () {
      this.resetValidation()
      if (!this.loading) {
        this.loading = true
        const fd = new FormData()
        fd.append('email', this.userForm.email.value)
        fd.append('password', this.userForm.password.value)
        fd.append('password_confirmation', this.userForm.password_confirmation.value)
        fd.append('token', this.$route.params.token)
        this.$store.dispatch('RESET_PASSWORD', fd).then(() => {
          this.$swal('Ваш пароль обновлен! Теперь попробуйте войти в аккаунт')
          this.loading = false
          this.$router.push({ name: 'account' })
        }).catch(({ response }) => {
          this.loading = false
          // Вывод ошибок валидаций от Laravel
          Object.keys(response.data).forEach(message => {
            Object.keys(this.userForm).forEach(field => {
              if (field === message) {
                this.userForm[field].message = response.data[message][0]
              }
            })
          })
        })
      }
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Сброс пароля',
      '',
      '',
      'Сброс пароля',
      null,
      ''
    )
  }
}
</script>
